const taw9eelArbStaging = {
  search: {
    inputBox: ".search-bar-box input",
    queryBtn: ".form-search button[type='submit']",
    urlParam: "q",
  },
  productClickGrid: {
    productElemWrapper: ".products-grid .badges-item",
    pidSelector: ".add-to-cart button.add-to-cart-btn",
    productPidAttr: "id-data",

    // qty related selectors to be added to Product config if the SRP has qty
    qtySelector: '.badges-item .qty .add-to-cart-qty',

    immediate: true,
    source: "PRODUCT_CLICK_GRID",
  },
  catPage: {
    catPageSelector: ".catalog-category-view",
    getCategoryPathCB: function (pathname) {
      return pathname.replace(/\/en\/|\/ar\//, "");
    },
  },
  addToCartFromSRP: {
    addToCartBtnSelector: ".products-grid .add-to-cart button.add-to-cart-btn",
    source: "ADD_TO_CART_SRP",
  },
  addToCartFromCartPopup: {
    cartItemWrapper: "#cart-sidebar .cart-item",
    productPidAttr: "pid",
    qtySelector: "#cart-sidebar .cart-item .qty .add-to-cart-qty",
    source: "ADD_TO_CART_FROM_CART_POPUP",
  },
  addToCartFromPDP: {
    addToCartBtnSelector: ".product-shop .add-to-cart button.add-to-cart-btn",
    pidSelector: ".add-to-cart button.add-to-cart-btn",
    productPidAttr: "id-data",
    qtySelector: ".product-actions .qty input",
    source: "ADD_TO_CART_PDP",
  },
  addToCartFromCartPage: {
    cartItemWrapper: "#shopping-cart-form .cart-product-row",
    productPidAttr: "pid",
    qtySelector: "#shopping-cart-form .cart-product-row .qty .add-to-cart-qty",
    source: "ADD_TO_CART_FROM_CART_PAGE"
  },
  pageView: {
    delay: 1500,
    events: {
      home: {
        uniqueSelectors: {
          selectors: [".widget-baner-slider"],
        },
        urlIdentifiers: {
          urls: ["https://stage.taw9eel.com/"],
          exactMatch: true,
        },
      },
      search: {
        uniqueSelectors: {
          selectors: [".catalogsearch-result-index", "#searchResultsWrapper #unbxd-results-count"],
        },
        urlIdentifiers: {
          urls: ["/catalogsearch"],
          exactMatch: false,
        },
        searchRecall: {
          noOfResultsSelector: '#searchResultsWrapper #unbxd-results-count',
          getSRCountCB: function (str) {
            const match = str.match(/(\d+)/);
            const number = match ? match[0] : null;
            return number;
          }
        }
      },
      category: {
        uniqueSelectors: {
          selectors: [".catalog-category-view"],
        },
      },
      productDisplay: {
        uniqueSelectors: {
          selectors: [".catalog-product-view"],
        },
      },
      cart: {
        uniqueSelectors: {
          selectors: [".checkout-cart-index"],
        },
        urlIdentifiers: {
          urls: ["/checkout/cart/"],
          exactMatch: false,
        },
      },
    },
  },
}

export default taw9eelArbStaging;
